<template>
  <Logs :id="id" log-type="Evaluation" />
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
// Components
import Logs from "@/components/templates/Logs";

export default {
  components: {
    Logs
  },
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();

    // COMPUTED
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.logs")} - ${t("app.quizzes", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.resource?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    return {
      documentTitle
    };
  }
};
</script>
